// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles__container___g59Nt{\n  display:flex;\n  flex:1;\n  height:100%;\n  flex-direction:row;\n  justify-content:center;\n  align-items:center;\n  border-radius:4px;\n  transition:all .5s cubic-bezier(0.25, 0.1, 0.25, 1);\n  cursor:pointer;\n  background-color:#ebebeb\n}\n\n.styles__container___g59Nt.styles__isDragActive___1rSza{\n  background-color:#c3c3c3\n}\n\n.styles__container___g59Nt div{\n  display:flex;\n  flex:1;\n  height:100%;\n  justify-content:center;\n  align-items:center\n}\n\n.styles__active___3mE_c{\n  background-color:silver\n}\n\n.styles__label___1E287{\n  margin-left:5px;\n  font-size:16px;\n  font-weight:bold\n}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EAAW,YAAY;EAAC,MAAM;EAAC,WAAW;EAAC,kBAAkB;EAAC,sBAAsB;EAAC,kBAAkB;EAAC,iBAAiB;EAAC,mDAAmD;EAAC,cAAc;EAAC;AAAwB;;AAAC;EAAwB;AAAwB;;AAAC;EAAe,YAAY;EAAC,MAAM;EAAC,WAAW;EAAC,sBAAsB;EAAC;AAAkB;;AAAC;EAAQ;AAAuB;;AAAC;EAAO,eAAe;EAAC,cAAc;EAAC;AAAgB","file":"styles.scss","sourcesContent":[".container{display:flex;flex:1;height:100%;flex-direction:row;justify-content:center;align-items:center;border-radius:4px;transition:all .5s cubic-bezier(0.25, 0.1, 0.25, 1);cursor:pointer;background-color:#ebebeb}.container.isDragActive{background-color:#c3c3c3}.container div{display:flex;flex:1;height:100%;justify-content:center;align-items:center}.active{background-color:silver}.label{margin-left:5px;font-size:16px;font-weight:bold}"]}]);
// Exports
exports.locals = {
	"container": "styles__container___g59Nt",
	"isDragActive": "styles__isDragActive___1rSza",
	"active": "styles__active___3mE_c",
	"label": "styles__label___1E287"
};
module.exports = exports;
